import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { setupInterceptors } from "./Interceptors";

export const api = axios.create({
  baseURL: import.meta.env.VITE_NMIBLE_SERVER,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    "Access-Control-Allow-Origin": "*",
    version: "v0.1",
  },
});

export const evervaultAPI = axios.create({
  baseURL: import.meta.env.VITE_EVERVAULT_SERVER,
  headers: {
    Accept: "application/vnd.api+json",

    "Content-Type": "application/vnd.api+json",
    version: "v0.1",
  },
});

export const loqateAPI = axios.create({
  baseURL: import.meta.env.VITE_LOQATE_SERVER,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
});

setupInterceptors(api);

export const useAPI = <T>(
  service: AxiosRequestConfig,
  fetch: boolean = true,
  deps: any[] = []
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    if (!fetch) return;

    setLoading(true);

    api<T>(service)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error as AxiosError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetch, ...deps]);

  return { data, setData, loading, error };
};
