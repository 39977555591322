import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setVisits, setVisitsLoading } from "../redux/slices/visitsSlice";
import { getVisits } from "../api/visitsService";
import { useErrorBoundary } from "react-error-boundary";
import { $api } from "../api/OpenAPI/client";
import { Visit } from "../api/OpenAPI/api-types";

export const useVisits = () => {
  const dispatch = useDispatch();
  // const visits = useSelector((state: RootState) => state.visits.data);
  // const isLoading = useSelector((state: RootState) => state.visits.isLoading);
  const { showBoundary } = useErrorBoundary();

  const { data, isLoading } = $api.useQuery("get", "/visits");

  // if (!data || isLoading) return "Loading...";
  // if (error) return `An error occured: ${error.message}`;
  const visits: Visit[] = data?.data ?? [];
  dispatch(setVisits(visits));
  return { visits, isLoading };

  // useEffect(() => {
  //   if (visits.length === 0 && !isLoading) {
  //     dispatch(setVisitsLoading(true));

  //     // Simulate API delay with setTimeout
  //     setTimeout(() => {
  //       try {
  //         dispatch(setVisits(mockTrialVisits));
  //       } catch (error) {
  //         showBoundary(error);
  //       } finally {
  //         dispatch(setVisitsLoading(false));
  //       }
  //     }, 1000);
  //   }
  // }, [dispatch, visits, isLoading, showBoundary]);

  // return { visits, isLoading };
};
