// Visits Slice

import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Visit } from "../../api/OpenAPI/api-types";

export const RESET_STATE = "RESET_STATE";

type VisitsState = {
  isLoading: boolean;
  data: Visit[];
};

const initialState: VisitsState = {
  isLoading: false,
  data: [],
};

const visitsSlice = createSlice({
  name: "visits",
  initialState,
  reducers: {
    setVisitsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVisits: (state, action: PayloadAction<Visit[]>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const visitsSourceData = (state: RootState) => state.visits.data;
export const receiptClaimData = (state: RootState) => state.claims.receiptClaim;

export const selectSelectedVisit = createSelector(
  [visitsSourceData, receiptClaimData],
  (visits, receiptClaim): Visit | undefined => {
    if (!receiptClaim || !receiptClaim.visit_id) return undefined;

    return visits.find((visit: Visit) => visit.id === receiptClaim.visit_id);
  }
);

export const { setVisits, setVisitsLoading } = visitsSlice.actions;

export default visitsSlice.reducer;
