import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { useVisits } from "../common/useVisits";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import {
  setMileageClaimVisit,
  setMileageClaimVisitDate,
  setReceiptClaimVisit,
  setReceiptClaimVisitDate,
} from "../redux/slices/claimsSlice";
import { RootState, store } from "../redux/store";
import { dateString, isFutureDate } from "../types/common";
import CardContainer from "./CardContainer";
import { Visit } from "../api/OpenAPI/api-types";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function ClaimVisitsPage() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [visitDate, setVisitDate] = useState<string | undefined>();
  const visitId = useSelector((state: RootState) => {
    if (id === "receipt") {
      return state.claims?.receiptClaim?.visit_id;
    } else {
      return state.claims?.mileageClaim?.visit_id;
    }
  });

  const storedVisitDate = useSelector((state: RootState) => {
    if (id === "receipt") {
      return state.claims?.receiptClaim?.visit_date;
    } else {
      return state.claims?.mileageClaim?.visit_date;
    }
  });

  useEffect(() => {
    if (storedVisitDate) {
      setVisitDate(storedVisitDate);
    } else {
      var now = dayjs();
      setSelectedDate(now);
    }
  }, []);

  const hideVisitMetaData =
    store.getState().auth.profile?.hide_visits_meta_info;

  usePageTitle(
    id === "receipt"
      ? t("claim_chooseClaimReceipt")
      : t("claim_chooseClaimMileage")
  );

  const [selectedVisit, setSelectedVisit] = useState<string>(visitId || "");
  // const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);

  function setSelectedDate(date: Dayjs | null | undefined) {
    if (!date) return;
    const formattedDate = date.toISOString();
    setVisitDate(formattedDate);
    if (id === "receipt") {
      dispatch(setReceiptClaimVisitDate(formattedDate));
    } else if (id === "mileage") {
      dispatch(setMileageClaimVisitDate(formattedDate));
    }
  }

  const dispatch = useDispatch();
  const { visits, isLoading } = useVisits();

  const navigate = useNavigate();

  const handleVisitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVisit(event.target.value);
    setSelectedDate(null);
  };

  const onContinue = () => {
    if (!selectedVisit) return;

    if (id === "receipt") {
      dispatch(setReceiptClaimVisit(selectedVisit));
      navigate("/payment/submit/receipt/categories");
    } else if (id === "mileage") {
      dispatch(setMileageClaimVisit(selectedVisit));
      navigate("/payment/submit/mileage/distance");
    }
  };

  return (
    <CardContainer
      title={t("claimDetail_relatedVisit")}
      subtitle={`${
        id === "receipt"
          ? t("receiptClaim_visits_description")
          : t("mileagesVisits_HeaderSubTitle")
      }. ${t("mileagesVisits_pastVisitsNote")}`}
      topRightContent={id === "receipt" ? <ReceiptImagePreview /> : null}
    >
      <form>
        <FormControl component="fieldset" fullWidth>
          {isLoading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={56}
                sx={{ borderRadius: 1, mb: 1 }}
              />
            ))
          ) : (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedVisit}
              onChange={handleVisitChange}
            >
              {visits.map((visit: Visit) => (
                <>
                  <FormControlLabel
                    disabled={false}
                    key={visit.id!}
                    value={visit.id!}
                    control={<Radio sx={hideVisitMetaData ? {} : { pt: 0 }} />}
                    label={
                      <Stack direction="column">
                        <Typography data-test-id="visit-name" variant="body1">
                          {visit.attributes.name}
                        </Typography>
                        <Typography
                          data-test-id="visit-date"
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          {dateString(visit)}
                        </Typography>
                      </Stack>
                    }
                    sx={{ my: 1 }}
                  />
                  {selectedVisit === visit.id && (
                    <Box my={1} sx={{ maxWidth: "350px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t("select_exact_date")}
                          value={visitDate}
                          onChange={setSelectedDate}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  )}
                </>
              ))}
            </RadioGroup>
          )}
        </FormControl>

        <Button
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
          disabled={!selectedVisit || !storedVisitDate}
          onClick={onContinue}
          data-test-id="submit-button"
        >
          {t("continue_text")}
        </Button>
      </form>
    </CardContainer>
  );
}

export default ClaimVisitsPage;
