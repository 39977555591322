import { Box, Typography } from "@mui/material";
import { cardV1faqData, cardV2faqData, FaqItem } from "./models";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardFAQDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const datasource: FaqItem[] = cardV2faqData;
  const idx = parseInt(id ?? "", 10);
  const item = datasource[idx];
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "calc(100vh - 56px - 32px)",
        px: 1,
        py: 1.5,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t(item.question)}
      </Typography>

      {item.answer && (
        <Typography mb={1} variant="body1">
          {t(item.answer)}
        </Typography>
      )}

      {item.children && item.children}
    </Box>
  );
};

export default CardFAQDetailsPage;
