import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStudy } from "../api/AuthService";
import { useAPI } from "../api/api";
import { useErrorHandler } from "../common/hooks";
import { RootState } from "../redux/store";
import StorageManager from "../services/storage";
import { Study } from "../types/paymentTypes";
import CardContainer from "./CardContainer";
import { $api } from "../api/OpenAPI/client";
import { setProfile } from "../redux/slices/authSlice";

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: any;
}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box padding={1}>
      <Typography data-test-id={label} variant="body1" fontWeight={"bold"}>
        {label}
      </Typography>
      <Typography data-test-id={`${label}-value`} variant="body2">
        {children}
      </Typography>
    </Box>
  </Grid>
);

export default function TrialDetailsPage() {
  const { t } = useTranslation();
  // const { handleServerError } = useErrorHandler();

  const {
    data: profileData,
    error,
    isLoading,
  } = $api.useQuery("get", "/participant/auth/who", {
    params: {
      query: { include: "arm.site,arm.site.study" },
    },
  });

  if (isLoading || error) {
    return (
      <CardContainer>
        <Grid data-test-id="skeleton" container spacing={2}>
          {Array.from(new Array(9)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" height={60} />
            </Grid>
          ))}
        </Grid>
      </CardContainer>
    );
  }

  const profile = profileData?.data?.attributes;
  const site = profileData?.included?.filter(
    (item) => item.type === "sites"
  )?.[0]?.attributes;
  const study = profileData?.included?.filter(
    (item) => item.type === "studies"
  )?.[0].attributes;

  if (!profile || !site || !study) return null;

  function getFullAddress(): string {
    const { line1, line2, line3, town, postcode } = site!;

    // Filter out null or empty values and join with a comma
    const formattedAddress = [line1, line2, line3, town, postcode]
      .filter((line) => line && line.trim().length > 0)
      .join(", ");

    return formattedAddress || "Address not available";
  }

  return (
    <CardContainer title={study.name} subtitle={t("visits_trialSubtitle")}>
      <Grid container spacing={2}>
        <ValueWithLabel label={t("auth_participantIdNumber")}>
          {profile.clientId}
        </ValueWithLabel>

        <ValueWithLabel label={t("trialDetails_studyNumber")}>
          {site.number}
        </ValueWithLabel>

        <ValueWithLabel label={t("trialDetails_description")}>
          {study.phase}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_sponsor")}>
          {study.sponsor}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_location")}>
          {getFullAddress()}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactName")}>
          {site.pointOfContactName}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactPhone")}>
          {site.pointOfContactPhoneNumber}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactEmail")}>
          {site.pointOfContactEmail}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_description")}>
          {study.description}
        </ValueWithLabel>
      </Grid>
    </CardContainer>
  );
}
