import getSymbolFromCurrency from "currency-symbol-map";
import i18n, { TFunction } from "i18next";
import { evervaultAPI } from "../api/api";
import {
  decryptionService,
  decryptionTokenService,
} from "../api/decryptionService";
import { ClaimItem } from "../types/paymentTypes";
import { ServerError } from "../api/OpenAPI/api-types";

export const generateDeclinedDescription = (text: string) => {
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  const emailMatch = text.match(emailRegex);

  if (emailMatch) {
    const email = emailMatch[0];
    const startIndex = text.indexOf(email);
    const endIndex = startIndex + email.length;

    return (
      <>
        {text.substring(0, startIndex)}
        {email}
        {text.substring(endIndex)}
      </>
    );
  }
  return text;
};

export async function getDecryptionValue(accIdentifier: string) {
  const tokenResponse = await evervaultAPI<TokenWrapper>(
    decryptionTokenService(accIdentifier)
  );

  const decryptedValue = await evervaultAPI(
    decryptionService(accIdentifier, tokenResponse.data.token)
  );

  return decryptedValue;
}

interface TokenWrapper {
  token: string;
}

export function formatNumber(num: number | string): string {
  const numberToFormat = typeof num === "string" ? parseFloat(num) : num;

  if (isNaN(numberToFormat)) {
    console.error(`formatNumber() - Unable to parse number from input:`, num);
    return "0.00";
  }

  return numberToFormat.toFixed(2);
}

export function convertDateToFormattedString(dateString: string): string {
  const date = new Date(dateString);
  const currentLanguage = i18n.language;

  const formattedDate = date.toLocaleString(currentLanguage, {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });

  return formattedDate;
}

export function removeSpacesAndHyphens(str: string) {
  return str.replace(/[\s-]/g, "");
}

export function stripTextAfterHyphen(text: string) {
  const hyphenIndex = text.indexOf("-");

  if (hyphenIndex === -1) {
    return text; // Return the original text if no hyphen is found
  }

  return text.substring(0, hyphenIndex); // Return the text before the hyphen
}

export function matchLocale(
  availableLocales: string[],
  preferredLocale: string
): string | undefined {
  // Exact match
  if (availableLocales.includes(preferredLocale)) {
    return preferredLocale;
  }

  // Partial match (e.g., match 'en' in 'en-US' if 'en-US' is not found)
  const generalLocale = preferredLocale.split("-")[0];
  const partialMatch = availableLocales.find(
    (locale) => locale.split("-")[0] === generalLocale
  );
  return partialMatch;
}

export function getLocalizedMessage(
  messageObject: string | undefined
): string | undefined {
  if (messageObject) {
    try {
      let messages: { [key: string]: string } = JSON.parse(messageObject);
      const currentLanguage: string = i18n.language;
      let availableLanguages = Object.keys(messages);
      let locale = matchLocale(availableLanguages, currentLanguage);
      if (locale) {
        let localizedMessage: string | undefined = messages[locale];
        return localizedMessage;
      }
    } catch (error) {
      console.error("Error parsing JSON string:", error);
      return undefined;
    }
  }

  return undefined;
}

export function getNameInitials(name: string): string {
  return name
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase())
    .join("");
}

export function totalAmount(items: ClaimItem[]): number {
  return items.reduce((sum, item) => {
    return sum + ((item.amount_to_pay ?? item.amount) || 0);
  }, 0);
}

export function getSymbol(currency: string) {
  return getSymbolFromCurrency(currency);
}

export function getErrorMsg(t: TFunction, error?: ServerError[] | ServerError) {
  const generalErrorMsg = t("error_somethingWentWrong");
  if (!error) return generalErrorMsg;

  if (Array.isArray(error)) {
    return error.map((item) => item.detail).join("\n");
  }

  return error.detail ?? generalErrorMsg;
}

export function getPageNumber(url: string) {
  try {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    const pageNumber = params.get("page[number]");
    return pageNumber ? parseInt(pageNumber, 10) : 1;
  } catch (error) {
    console.error("Invalid URL:", error);
    return 1;
  }
}
