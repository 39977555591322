import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useTranslation } from "react-i18next";

interface PendingStipend {
  id: string;
  attributes: {
    amount: string;
    dueDate: string;
    title: string;
    status: string;
  };
}

interface PendingStipendsListProps {
  stipends: PendingStipend[];
}

const PendingStipendsList: React.FC<PendingStipendsListProps> = ({
  stipends,
}) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStipendId, setCurrentStipendId] = useState<string | null>(null);
  const [visibleStipends, setVisibleStipends] = useState(stipends);

  useEffect(() => {
    const persistedStipends = sessionStorage.getItem("actionedStipends");
    if (persistedStipends) {
      const actionedStipends = JSON.parse(persistedStipends);
      setVisibleStipends(
        stipends.filter((s) => !actionedStipends.includes(s.id))
      );
    }
  }, [stipends]);

  const updateSessionStorage = (id: string) => {
    const actionedStipends = JSON.parse(
      sessionStorage.getItem("actionedStipends") || "[]"
    );
    actionedStipends.push(id);
    sessionStorage.setItem(
      "actionedStipends",
      JSON.stringify(actionedStipends)
    );
  };

  const handleAccept = async (id: string) => {
    setLoading(true);
    setCurrentStipendId(id);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    updateSessionStorage(id);
    setVisibleStipends((prev) => prev.filter((stipend) => stipend.id !== id));
    setLoading(false);
    setCurrentStipendId(null);
  };

  const handleReject = (id: string) => {
    setCurrentStipendId(id);
    setOpenDialog(true);
  };

  const confirmReject = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    updateSessionStorage(currentStipendId!);
    setVisibleStipends((prev) =>
      prev.filter((stipend) => stipend.id !== currentStipendId)
    );
    setLoading(false);
    setOpenDialog(false);
    setRejectionReason("");
    setCurrentStipendId(null);
  };

  if (!visibleStipends || visibleStipends.length === 0) {
    return null;
  }

  const sectionTitle = (title: string) => {
    return (
      <Typography
        sx={{
          mt: 2,
          mb: 1,
        }}
        variant="subtitle2"
        color="text.secondary"
        fontWeight="600"
      >
        {title}
      </Typography>
    );
  };

  return (
    <>
      {sectionTitle(t("claim_stipend_sectionTitle"))}

      <List>
        {visibleStipends.map((stipend) => {
          const formattedDate = new Date(
            stipend.attributes.dueDate
          ).toLocaleDateString("en-US", { day: "2-digit", month: "short" });

          return (
            <ListItem
              key={stipend.id}
              sx={{
                backgroundColor: "white",
                border: "1px solid #efefef",
                borderRadius: "8px",
                mb: 2,
              }}
            >
              <Avatar sx={{ bgcolor: "gray", mr: 2 }}>
                <HourglassEmptyIcon />
              </Avatar>
              <Box flex="1">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="subtitle1">
                    {stipend.attributes.title || t("no_description")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                    color="textSecondary"
                  >
                    {`$ ${stipend.attributes.amount}`}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="textSecondary">
                    {`${t("due")}: ${formattedDate}`}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleAccept(stipend.id)}
                      loading={
                        loading &&
                        currentStipendId === stipend.id &&
                        !openDialog
                      }
                    >
                      {t("accept")}
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleReject(stipend.id)}
                      loading={
                        loading && currentStipendId === stipend.id && openDialog
                      }
                    >
                      {t("reject")}
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </List>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{t("reject_stipend")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="rejection-reason"
            label={t("rejection_reason")}
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={() => setOpenDialog(false)} color="primary">
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            onClick={confirmReject}
            color="primary"
            loading={loading}
          >
            {t("confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PendingStipendsList;
