import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorType, getErrorDetails } from "./CustomErrorBoundary";
import { t } from "i18next";
import { showError } from "../redux/slices/toastSlice";
import { IIdleTimer, useIdleTimer } from "react-idle-timer";
import { store } from "../redux/store";

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const useErrorHandler = () => {
  const { showBoundary } = useErrorBoundary();

  const handleServerError = (error: AxiosError) => {
    const errorDetails = getErrorDetails(error, t);

    if (errorDetails) {
      if (
        errorDetails.type != ErrorType.TERMS_UPDATED &&
        errorDetails.type != ErrorType.UNAUTHORIZED &&
        errorDetails.type != ErrorType.NO_CONNECTION &&
        errorDetails.method != "get"
      ) {
        if (errorDetails.props) {
          store.dispatch(
            showError({
              title: errorDetails.props.title,
              message: errorDetails.props.message,
            })
          );
        }
      } else {
        // Error Boundary will handle terms redirect, unauthorized redirect, no connection and show generic error for all GET methods
        showBoundary(error);
      }
    }
  };

  return {
    handleServerError,
  };
};

export const useIdleTimeout = (
  onPrompt: () => void,
  onIdle: (event?: Event, idleTimer?: IIdleTimer) => void,
  idleTimeInMinutes: number
) => {
  const idleTimeout = 1000 * 60 * idleTimeInMinutes;

  const [isIdle, setIdle] = useState(false);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: 60 * 1000, // One minute before logout
    onPrompt: onPrompt,
    onIdle: onIdle,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

export const useCountdown = (timeRemainingMiliseconds: number) => {
  const [countDown, setCountDown] = useState<number>(timeRemainingMiliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  const reset = () => {
    setCountDown(timeRemainingMiliseconds);
  };

  return {
    timeLeftValues: getReturnValues(countDown),
    reset: reset,
  };
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

export default useCheckMobileScreen;
