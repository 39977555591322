import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

interface CardContainerProps {
  title?: string;
  subtitle?: string;
  topRightContent?: React.ReactNode;
  children: React.ReactNode;
  showBorder?: boolean;
  icon?: React.ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({
  icon,
  title,
  subtitle,
  topRightContent,
  children,
  showBorder = false,
}) => {
  return (
    <Paper
      id="card_container"
      elevation={0}
      square={false}
      variant="outlined"
      sx={{
        mt: 2,
        mb: 2,
        borderWidth: showBorder ? "1px" : 0,
      }}
    >
      {(title || subtitle || topRightContent) && (
        <>
          <Grid
            data-test-id="container"
            container
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", padding: "20px 20px 0" }}
            >
              {" "}
              {icon && icon}
              <Grid item>
                {title && (
                  <Typography
                    data-test-id="container-title"
                    variant="h6"
                    fontWeight="bold"
                  >
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    data-test-id="container-subtitle"
                    variant="subtitle1"
                    color="gray"
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>
            </Stack>

            {topRightContent && (
              <Grid
                data-test-id="container-top-right-content"
                item
                sx={{ pl: "20px" }}
              >
                {topRightContent}
              </Grid>
            )}
          </Grid>
          <Divider />
        </>
      )}

      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  );
};

export default CardContainer;
