import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

interface SupportedLanguages {
  [language: string]: {
    regions: Region[];
    default: string;
  };
}
interface Region {
  id: string;
  name: string;
}
interface LocaleResult {
  locale: string;
  usedFallback: boolean;
}

function getLocale(
  languageIdentifier: string,
  supportedLanguages: SupportedLanguages,
  countryCode?: string
): LocaleResult {
  const parts = languageIdentifier.split("-");
  const language = parts[0].toLowerCase();
  const region = parts.length > 1 ? parts[1].toUpperCase() : undefined;

  // First priority is given to the provided countryCode, if valid
  const regionalIdentifier = countryCode
    ? `${language}-${countryCode.toUpperCase()}`
    : region
    ? `${language}-${region}`
    : language;

  // Check if the language is supported
  if (supportedLanguages[language]) {
    // Check for exact regional match or a match with countryCode
    if (
      regionalIdentifier &&
      supportedLanguages[language].regions
        .map((item) => item.id)
        .includes(regionalIdentifier)
    ) {
      return { locale: regionalIdentifier, usedFallback: false };
    }

    // Fallback to the default for the language
    return {
      locale: supportedLanguages[language].default,
      usedFallback: false,
    };
  }

  // Fallback to 'en-GB' if language not supported
  return { locale: "en-GB", usedFallback: true };
}

const customDetector = (lng: string) => {
  const determinedLocale = getLocale(lng, supportedLanguages);

  return determinedLocale.locale;
};

const languageNames: { [key: string]: string } = {
  bg: "Български",
  de: "Deutsch",
  "en-GB": "English",
  "es-ES": "Español",
  "es-419": "Español (Latinoamérica)",
  "fr-FR": "Français",
  pt: "Português",
  vi: "Tiếng Việt",
  zh: "华语",
  "it-IT": "Italiano",
  "ja-JP": "日本語",
  "ko-KR": "한국어",
  ar: "العربية",
  cs: "Čeština",
  da: "Dansk",
  et: "Eesti",
  lt: "Lietuvių",
  nl: "Nederlands",
  "no-NO": "Norsk",
  pl: "Polski",
  ru: "Русский",
  th: "ไทย",
};

export const getLanguageName = (regionId: string): string => {
  return languageNames[regionId] || regionId;
};

export const getSupportedLanguages = (): SupportedLanguages => {
  const languages: SupportedLanguages = {};

  const supportedLanguagesEnv: string | undefined = import.meta.env
    .VITE_SUPPORTED_LANGUAGES;
  if (!supportedLanguagesEnv) {
    console.error("No supported languages found in environment variables.");
    return languages;
  }

  const languageKeys = supportedLanguagesEnv.split(",");
  languageKeys.forEach((language) => {
    const regionEnvKey = `VITE_LANGUAGE_REGIONS_${language}`;
    const regionsEnv: string = import.meta.env[regionEnvKey];
    if (regionsEnv) {
      const regions = regionsEnv.split(",").map((region) => ({
        id: region.trim(),
        name: getLanguageName(region.trim()),
      }));

      languages[language] = {
        regions,
        default: regions[0].id, // Set the first region as the default
      };
    }
  });

  return languages;
};

export const supportedLanguages: SupportedLanguages = getSupportedLanguages();

export const getRegionById = (regionId: string): Region | undefined => {
  for (const languageKey in supportedLanguages) {
    const language = supportedLanguages[languageKey];
    const region = language.regions.find((item) => item.id === regionId);
    if (region) {
      return region;
    }
  }

  return undefined;
};

export const getAllRegions = (): Region[] => {
  const regions: Region[] = [];
  Object.values(supportedLanguages).forEach((language) => {
    regions.push(...language.regions);
  });
  return regions;
};

export const changeUserLanguage = (language: string): LocaleResult => {
  const localeResult = getLocale(language ?? "en-GB", supportedLanguages);

  i18n.changeLanguage(localeResult.locale);

  return localeResult;
};

const i18nInstance = i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: "currentOnly",
    fallbackLng: "en-GB",
    supportedLngs: Object.keys(supportedLanguages).flatMap((lang) =>
      supportedLanguages[lang].regions.map((item) => item.id)
    ),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupQuerystring: "lng",
      convertDetectedLanguage: customDetector,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18nInstance;
