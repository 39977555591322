import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AvailablePaymentMethod,
  PaymentAccountDetails,
  PaymentType,
  StateItem,
} from "../../types/paymentTypes";
import { RootState } from "../store";
import PaymentMethodsData from "../../api/payment-methods.json";

export const RESET_STATE = "RESET_STATE";

interface PaymentMethodsState {
  availablePaymentMethods: AvailablePaymentMethod[];
  latestPaymentAccount: PaymentAccountDetails | null;
  paymentMethod?: string;
  formStep: number;
  states: StateItem[];
}

const initialState: PaymentMethodsState = {
  availablePaymentMethods: PaymentMethodsData,
  latestPaymentAccount: null,
  paymentMethod: undefined,
  formStep: 1,
  states: [],
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    setLatestPaymentMethod: (
      state,
      action: PayloadAction<PaymentAccountDetails>
    ) => {
      state.latestPaymentAccount = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.formStep = action.payload;
    },
    setStates: (state, action: PayloadAction<StateItem[]>) => {
      state.states = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const paymentMethods = (state: RootState) =>
  state.paymentMethods.availablePaymentMethods;

export const paymentMethodType = (
  state: RootState,
  paymentMethodType: PaymentType
) => paymentMethodType;

export const getPaymentMethodByType = createSelector(
  [paymentMethods, paymentMethodType],
  (paymentMethods, paymentMethodType): AvailablePaymentMethod | undefined => {
    return paymentMethods?.find((x) => x.payment_type === paymentMethodType);
  }
);

export const getPaymentMethodIdByType = createSelector(
  [paymentMethods, paymentMethodType],
  (paymentMethods, paymentMethodType): number | undefined => {
    return paymentMethods?.find((x) => x.payment_type === paymentMethodType)
      ?.id;
  }
);

export const {
  setStates,
  setLatestPaymentMethod,
  setFormStep,
  setPaymentMethod,
} = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
