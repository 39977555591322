import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";

interface CardContextProps {
  toggleCardVisibility: () => void;
  setCustomToggleFunction: (toggleFunction: () => void) => void;
  cardVisible: boolean;
}

const CardContext = createContext<CardContextProps | undefined>(undefined);

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error("useCardContext must be used within a CardProvider");
  }
  return context;
};

export const CardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cardVisible, setCardVisible] = useState(false);

  // Default toggle function to toggle visibility
  const defaultToggleFunction = useCallback(() => {
    setCardVisible((prev) => !prev);
  }, []);

  const [customToggleFunction, setCustomToggleFunction] = useState<() => void>(
    () => {}
  );

  // Wrapper function that combines the custom function and state update
  const toggleCardVisibility = useCallback(() => {
    customToggleFunction();
    setCardVisible((prev) => !prev);
  }, [customToggleFunction]);

  return (
    <CardContext.Provider
      value={{
        toggleCardVisibility,
        setCustomToggleFunction,
        cardVisible,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
