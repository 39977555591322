import { AxiosRequestConfig } from "axios";
import { LoginData } from "../types/common";

export const refreshTokenService = (
  refreshToken: string
): AxiosRequestConfig => {
  return {
    url: "/v1/auth/payee-refresh",
    method: "POST",
    data: {
      refresh_token: refreshToken,
    },
  };
};

// export const participantLoginService = (
//   data: LoginData
// ): AxiosRequestConfig => {
//   return {
//     url: data.isNewUser
//       ? "/participant/auth/onboard"
//       : "/participant/auth/login",
//     method: "POST",
//     data: data,
//   };
// };

export const getStudy = (): AxiosRequestConfig => {
  return {
    url: "/claimants/study/",
    method: "GET",
  };
};

export interface TaxInfo {
  email: string;
  full_name: string;
  is_paper_copy_requested: boolean;
}

export const updateTaxInfo = (data: TaxInfo): AxiosRequestConfig => {
  return {
    url: "/claimants/edit-email",
    method: "PUT",
    data: data,
  };
};

export const verifyEmail = (token: string): AxiosRequestConfig => {
  return {
    url: "/claimants/verify-email",
    method: "POST",
    data: { token: token },
  };
};

export const verifyPin = (pin: string): AxiosRequestConfig => {
  return {
    url: "/claimants/verify-pin",
    method: "POST",
    data: { pin },
  };
};

export const setTaxInfo = (data: TaxInfo): AxiosRequestConfig => {
  return {
    url: "/claimants/set-email",
    method: "POST",
    data: data,
  };
};

export const sendEnquiryEmail = (text: string): AxiosRequestConfig => {
  return {
    url: "/claimants/enquiry/",
    method: "POST",
    data: { text },
  };
};

export interface SetPinData {
  new_pin: string;
}

export interface ChangePinData {
  currentPin: string;
  newPin: string;
  newPin_confirmation: string;
}

export const setPinService = (
  data: SetPinData | ChangePinData
): AxiosRequestConfig => {
  return {
    url: "/participant/auth/update-pin",
    method: "POST",
    data: data,
  };
};
