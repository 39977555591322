import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  Box,
  Chip,
  Avatar,
  Stack,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  DirectionsCar as MileageIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import CardContainer from "./CardContainer";
import { useTranslation } from "react-i18next";
import { $api } from "../api/OpenAPI/client";

const ExpenseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  //   if (!id) return;
  //   const expense = mockExpenses.find((exp) => exp.id === id);

  const { data, isLoading } = $api.useQuery("get", "/expenses/{id}", {
    params: {
      query: {
        include:
          "expenseItems,receipt,participantVisit,expenseItems.expenseType",
      },
      path: {
        id: id ?? "0",
      },
    },
  });

  if (!data?.data) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const expenseType: string =
    data?.included?.filter((item) => item.type === "expense-types")?.[0]
      ?.attributes.name ?? "";
  const receipt_url: string =
    data?.included?.filter((item) => item.type === "receipts")?.[0]?.attributes
      .uri ?? "";
  const expense = data?.data;

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <CardContainer
      title={`${expenseType} Expense`}
      subtitle={t("claimDetail_claimWithId", { "0": expense.id })}
      icon={
        <Avatar sx={{ mr: 2 }}>
          {expenseType === "Mileage" ? <MileageIcon /> : <ReceiptIcon />}
        </Avatar>
      }
      topRightContent={
        receipt_url && <ReceiptImagePreview source={receipt_url} />
      }
    >
      <Stack spacing={2}>
        {expense.attributes.notes && (
          <Alert severity="error">
            <AlertTitle>{t("rejection_reason")}</AlertTitle>
            {expense.attributes.notes}
          </Alert>
        )}
        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Date</Typography>
          <Typography variant="h6">
            {new Date(expense.attributes.expenseDate).toLocaleDateString()}
          </Typography>
        </Stack>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Amount</Typography>
          <Typography variant="h6">${expense.attributes.amount}</Typography>
        </Stack>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Status</Typography>
          <Chip
            label={t(expense.attributes.status)}
            color={
              getStatusColor(expense.attributes.status) as
                | "success"
                | "error"
                | "warning"
            }
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
};

export default ExpenseDetails;
