import React from "react";
import { Divider, List, ListItem, ListSubheader } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cardV2faqData } from "./models";

const CardFAQsPage: React.FC = () => {
  const datasource = cardV2faqData;
  const { t } = useTranslation();

  return (
    <List
      sx={{ backgroundColor: "white", height: "calc(100vh - 56px - 32px)" }}
    >
      <ListSubheader>{t("card_faq_description")}</ListSubheader>

      {datasource.map((item, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <Divider key={`divider-${index}`} variant="middle" sx={{ my: 1 }} />
          )}
          <ListItem key={`list-item-${index}`}>
            <Link style={{ textDecoration: "none" }} to={`/card/faq/${index}`}>
              {t(item.question)}
            </Link>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default CardFAQsPage;
