import { Box } from "@mui/material";
import ImageModal from "./ImageModal";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { convertDataUrlToBlob } from "../types/common";

function ReceiptImagePreview(props: { source?: string }) {
  const url =
    props.source ??
    useSelector((state: RootState) => state.claims.receiptClaim)?.receiptData;
  if (!url) return;
  return (
    <Box
      sx={{
        width: "100px",
        height: "auto",
        overflow: "hidden",
        p: "20px 20px 0 0",
      }}
    >
      <ImageModal src={url} />
    </Box>
  );
}

export default ReceiptImagePreview;
