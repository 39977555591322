import React, { useTransition } from "react";

import { ErrorDetails } from "../../common/CustomErrorBoundary";
import ErrorBox from "../Modal/ErrorBox";
import { useTranslation } from "react-i18next";

type FormErrorBoxProps = {
  error: ErrorDetails | string | null;
};

const FormErrorBox: React.FC<FormErrorBoxProps> = ({ error }) => {
  const { t } = useTranslation();
  let title: string | undefined;
  let message: string;

  if (!error) {
    message = t("error_somethingWentWrong");
  } else {
    const isStringError = typeof error === "string";
    title = isStringError ? undefined : error.props?.title || "";
    message = isStringError ? error : error.props?.message || "";
  }

  return <ErrorBox title={title} text={message} marginBottom="20px" />;
};

export default FormErrorBox;
