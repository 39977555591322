import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button } from "@mui/material";

import { getLanguageName } from "../../i18n";
import { setIsLanguageSelectorDialogOpen } from "../../redux/slices/languageSelectorDialogSlice";

const LanguageButton: React.FC<{ color?: string }> = ({ color }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  // const { mode, toggleColorMode } = useContext(MUIWrapperContext);

  const currentLanguageText = useMemo(() => {
    if (i18n.language) {
      const name = getLanguageName(i18n.language);

      return name;
    }
  }, [i18n.language]);

  const handleFlagClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setIsLanguageSelectorDialogOpen({ isOpen: true }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <DarkModeSwitch
        moonColor="black"
        sunColor="white"
        style={{ marginRight: "8px", padding: "8px" }}
        checked={mode === "dark"}
        onChange={toggleColorMode}
        size={40}
      /> */}

      <Button
        data-test-id="language-button"
        onClick={handleFlagClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: color ?? "white", fontWeight: "600", pl: 0 }}
      >
        {currentLanguageText}
      </Button>
    </Box>
  );
};

export default LanguageButton;
