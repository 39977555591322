import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  // ListItemIcon,
  ListItemText,
  useTheme,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
// import { findFlagUrlByIso2Code } from "country-flags-svg";
import { api } from "../../api/api";
// import { updateProfile } from "../../api/userService";
import {
  changeUserLanguage,
  getRegionById,
  supportedLanguages,
} from "../../i18n";
import { setProfile } from "../../redux/slices/authSlice";
import { setIsLanguageSelectorDialogOpen } from "../../redux/slices/languageSelectorDialogSlice";
import { RootState } from "../../redux/store";
import { remapPaymentMethods } from "../../types/paymentTypes";
// import { getVisits } from "../../api/visitsService";
import { setVisits, setVisitsLoading } from "../../redux/slices/visitsSlice";
import { VisitsResponse } from "../../types/common";
import { useErrorHandler } from "../../common/hooks";
import { AxiosError } from "axios";

const languageSelectedOnLoginKey = "languageSelectedOnLogin";

interface LanguageRegions {
  [region: string]: string[];
}

const languageRegions: LanguageRegions = {
  Europe: [
    "bg",
    "cs",
    "da",
    "de",
    "et",
    "fr-FR",
    "it-IT",
    "lt",
    "nl",
    "no-NO",
    "pl",
    "pt",
    "ru",
    "es-ES",
  ],
  "Asia-Pacific": ["zh", "ja-JP", "ko-KR", "th", "vi"],
  "Middle East and Africa": ["ar"],
  Americas: ["en-GB", "es-419"],
};

const LanguageSelectorDialog: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDialogOpen =
    useSelector((state: RootState) => state.languageSelectorDialog.isOpen) ||
    false;
  const isForcedSelection =
    useSelector((state: RootState) => state.languageSelectorDialog.isForced) ||
    false;
  const availableStudyLanguages =
    useSelector(
      (state: RootState) => state.auth.profile?.available_languages
    ) || [];
  const userPrefferedLanguage =
    useSelector((state: RootState) => state.auth.profile?.preferred_language) ||
    "";

  const browserLanguage = useMemo(() => {
    const parts = navigator.language.split("-");
    const language = parts[0].toLowerCase();

    return language;
  }, [navigator.language]);

  const isLoginPage = useMemo(() => {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf("/login") > -1) {
      return true;
    }

    return false;
  }, [window.location.href]);

  useEffect(() => {
    const onLoginLanguageSelected = localStorage.getItem(
      languageSelectedOnLoginKey
    );

    // IF Browser language is supported by the study AND we DON'T have preffred language or login language we set it.
    if (
      supportedLanguages[browserLanguage] &&
      availableStudyLanguages.indexOf(
        supportedLanguages[browserLanguage].default.toLowerCase()
      ) > -1 &&
      !userPrefferedLanguage &&
      !onLoginLanguageSelected
    ) {
      handleLanguageSelect(supportedLanguages[browserLanguage].default);

      // If login language selected
    } else if (
      onLoginLanguageSelected &&
      availableStudyLanguages.indexOf(onLoginLanguageSelected.toLowerCase()) >
        -1
    ) {
      handleLanguageSelect(onLoginLanguageSelected);

      // IF the browser language is NOT supported OR we DO HAVE preffered language from the BE (set by other device maybe) that is supported we set it.
    } else if (availableStudyLanguages.indexOf(userPrefferedLanguage) > -1) {
      handleLanguageSelect(userPrefferedLanguage);

      // Since English is available everywhere we don't show the dialog if it's currently selected
      // TODO: Remove this when BE is done with English support.
    } else if (i18n.language == "en-GB") {
      return;

      // IF NEITHER the browser language OR preffered language are supported by the study we ask the user for language selector dialog.
      // NOTE: Language selector dialog lists only the languages supported by the study.
    } else {
      if (isLoginPage) {
        return;
      }

      dispatch(
        setIsLanguageSelectorDialogOpen({ isOpen: true, isForced: true })
      );
    }
  }, []);

  const handleLanguageSelectClick = (selectedLanguage: string) => {
    if (isLoginPage) {
      localStorage.setItem(languageSelectedOnLoginKey, selectedLanguage);
    } else {
      localStorage.removeItem(languageSelectedOnLoginKey);
    }

    handleLanguageSelect(selectedLanguage);
  };

  const handleLanguageSelect = async (selectedLanguage: string) => {
    setIsLoading(true);
    const currentLanguage = i18n.language;

    changeUserLanguage(selectedLanguage);

    if (!isLoginPage) {
      let requestData = {};

      if (selectedLanguage != "en-GB") {
        requestData = { preferred_language: selectedLanguage.toLowerCase() };
      }

      try {
        // let profileData = await api(updateProfile(requestData));
        // let visitsData = await api<VisitsResponse>(getVisits());
        // remapPaymentMethods(profileData);
        // dispatch(setProfile({ profile: profileData.data }));
        // dispatch(setVisitsLoading(true));
        // dispatch(setVisits(visitsData.data.visits));
      } catch (error) {
        changeUserLanguage(currentLanguage);
        handleServerError(error as AxiosError);
      }
    }

    dispatch(setIsLanguageSelectorDialogOpen({ isOpen: false }));
    setIsLoading(false);
  };

  const filterLanguages = (regionLanguages: string[]): string[] => {
    if (isLoginPage) {
      const supportedLngs = Array.isArray(i18n.options.supportedLngs)
        ? i18n.options.supportedLngs
        : [];
      return regionLanguages.filter((langId) => supportedLngs.includes(langId));
    } else if (availableStudyLanguages.length > 0) {
      return regionLanguages.filter((langId) =>
        availableStudyLanguages.includes(langId)
      );
    }
    return regionLanguages;
  };

  interface Group {
    region: string;
    filteredLanguages: string[];
  }
  const renderLanguageByRegion = () => {
    const isGroup = (group: Group | null): group is Group => group !== null;

    const regionsWithLanguages: Group[] = Object.keys(languageRegions)
      .map((region) => {
        const filteredLanguages = filterLanguages(languageRegions[region]);
        if (filteredLanguages.length === 0) return null; // Skip empty regions

        return { region, filteredLanguages }; // Return region and its filtered languages
      })
      .filter(isGroup); // Remove null values (regions with no languages)

    const manyRegions = regionsWithLanguages.length > 1;
    // Render regions with languages
    return regionsWithLanguages.map(({ region, filteredLanguages }, index) => (
      <div key={region}>
        {index !== 0 && <Divider sx={{ margin: "16px 0" }} />}
        {manyRegions && (
          <Typography variant="h6" align="left" gutterBottom>
            {region}
          </Typography>
        )}
        <Grid container spacing={2}>
          {filteredLanguages.map((languageId) => {
            const language = getRegionById(languageId);
            if (!language) return null;

            return (
              <Grid item xs={12} sm={6} md={4} key={language.id}>
                <ListItem
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: "transparent",
                      color: theme.palette.primary.main,
                    },
                    "&& .Mui-selected:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  disablePadding
                  onClick={() => handleLanguageSelectClick(language.id)}
                >
                  <ListItemButton
                    selected={language.id === i18n.language}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemText
                      primary={language.name}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </div>
    ));
  };

  return (
    <Dialog
      dir={theme.direction}
      onClose={() => {}}
      open={isDialogOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: "90%", // Full width on smaller screens
          maxWidth: "800px", // Max width for large screens
          margin: "0 auto", // Center it on larger screens
        },
      }}
    >
      <DialogTitle textAlign="center">
        {t("trialLanguage_title")}

        {!isForcedSelection && (
          <IconButton
            aria-label="close"
            onClick={() =>
              dispatch(setIsLanguageSelectorDialogOpen({ isOpen: false }))
            }
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent style={{ minWidth: "300px", textAlign: "center" }}>
        {!isLoading && (
          <form>
            <List>{renderLanguageByRegion()}</List>
          </form>
        )}

        {isLoading && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};

export default LanguageSelectorDialog;
