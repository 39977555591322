import PaymentIcon from "@mui/icons-material/Payment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $api } from "../../api/OpenAPI/client";

interface ManageCardProps {
  buttonLabel?: string;
}

const ManageCard: React.FC<ManageCardProps> = ({ buttonLabel }) => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const paymentMethodId = useSelector(
    (state: RootState) => state.paymentMethods.paymentMethod
  );

  if (!paymentMethodId) return null;

  const {
    data: paymentMethodData,
    error: paymentMethodError,
    isLoading: loadingPaymentMethodData,
  } = $api.useQuery("get", "/payment-methods/{id}", {
    params: {
      path: { id: paymentMethodId },
    },
  });

  if (loadingPaymentMethodData)
    return (
      <Paper data-test-id="payments-skeleton" elevation={0}>
        <Skeleton variant="rounded" height={144.5} />
      </Paper>
    );

  return (
    <>
      {/* {vccData && vccData.length > 0 && ( */}
      <Card
        variant="outlined"
        sx={{ border: "none", backgroundColor: "#140B42" }}
      >
        <CardContent
          sx={{
            position: "relative",
            backgroundImage: "url(/card/iqvia.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 15px top 18px",
            backgroundSize: "auto 50%",
            color: "white",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              {/* {formatNumber(vccData[0].balance, false, currencySymbol)} */}${" "}
              {paymentMethodData?.data?.attributes.balance}
            </Typography>
          </Box>

          <Typography variant="body2">{t("vcc_availableBalance")}</Typography>

          <Button
            startIcon={<PaymentIcon />}
            variant="outlined"
            sx={{
              marginTop: 2,
              color: "white",
              borderColor: "white",
              ":hover": { borderColor: "white" },
            }}
            onClick={() => {
              navigate("/card/details");
            }}
          >
            {buttonLabel ?? t("card_manage")}
          </Button>
        </CardContent>
      </Card>
      {/* )} */}
    </>
  );
};

export default ManageCard;
