import { configureStore, combineReducers } from "@reduxjs/toolkit";
import claimsReducer from "./slices/claimsSlice";
import vccReducer from "./slices/vccSlice";
import modalReducer from "./slices/modalSlice";
import toastReducer from "./slices/toastSlice";
import storage from "redux-persist/lib/storage";
import paymentMethodsReducer from "./slices/paymentMethodsSlice";
import languageSelectorDialog from "./slices/languageSelectorDialogSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import authSlice from "./slices/authSlice";
import visitsSlice from "./slices/visitsSlice";

export const RESET_STATE = "RESET_STATE";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["modal", "error", "languageSelectorDialog"],
};

const rootReducer = combineReducers({
  paymentMethods: paymentMethodsReducer,
  claims: claimsReducer,
  vcc: vccReducer,
  modal: modalReducer,
  toast: toastReducer,
  auth: authSlice,
  visits: visitsSlice,
  languageSelectorDialog: languageSelectorDialog,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const reset = async () => {
  const purgePersistedState = await persistor.purge();
  const resetReduxState = await Promise.resolve(
    store.dispatch({ type: RESET_STATE })
  );

  await Promise.all([purgePersistedState, resetReduxState]);
};
