import React, { useRef, useCallback, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import {
  DirectionsCar as MileageIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExpenseRowData } from "../types/common";

interface Props {
  data: ExpenseRowData[];
  loadMoreData: () => void;
}

const ExpensesList: React.FC<Props> = ({ data, loadMoreData }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  };

  const handleExpenseClick = (id: string) => {
    navigate(`/expense/${id}`);
  };

  const lastExpenseRef = useCallback(
    (node: HTMLLIElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setLoading(true);
          loadMoreData();
          setLoading(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadMoreData]
  );

  return (
    <List>
      {data.map((expense, index) => (
        <ListItem
          key={expense.id}
          ref={index === data.length - 1 ? lastExpenseRef : null}
          alignItems="flex-start"
          onClick={() => handleExpenseClick(expense.id)}
          sx={{
            backgroundColor: "white",
            border: "1px solid #efefef",
            borderRadius: "8px",
            mb: 2,
            cursor: "pointer",
          }}
        >
          <ListItemAvatar>
            <Avatar>
              {expense.type.toLowerCase() === "mileage" ? (
                <MileageIcon />
              ) : (
                <ReceiptIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  Expense - {expense.id}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  ${expense.amount}
                </Typography>
              </Box>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {expense.createdAt}
                </Typography>
                <Box mt={1}>
                  <Chip
                    label={t(expense.status)}
                    color={
                      getStatusColor(expense.status) as
                        | "success"
                        | "error"
                        | "warning"
                    }
                    size="small"
                  />
                </Box>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ExpensesList;
