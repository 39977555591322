import AirlinesIcon from "@mui/icons-material/Airlines";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { RootState } from "../redux/store";
import CardContainer from "./CardContainer";
import { useErrorHandler } from "../common/hooks";
import { AxiosError } from "axios";
import { calculateMileageRate } from "../services/payment";

function MileageDistancePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleServerError } = useErrorHandler();

  usePageTitle(t("claim_chooseClaimMileage"));

  const distance_unit = useSelector(
    (state: RootState) => state.auth.profile
  )!.distance_unit;
  const travelRateValue = useSelector(
    (state: RootState) => state.auth.site?.attributes.travelRateValue
  );

  const latestPaymentAccount = useSelector(
    (state: RootState) => state.paymentMethods.latestPaymentAccount
  );

  const previouslyInputedDistance = useSelector((state: RootState) => {
    if (state.claims.mileageClaim?.items?.length) {
      // If there are multiple rates on different distances, the input gets split to multiple items
      // Here we aggregate the total value
      return state.claims.mileageClaim?.items.reduce(
        (a, x) => a + x.distance,
        0
      );
    }
  });

  const [amount, setAmount] = useState(
    previouslyInputedDistance?.toString() || ""
  );
  const [isLoading, setLoading] = useState(false);

  const isContinueButtonDisabled = () => {
    const amountNumber = parseFloat(amount);

    return !(amountNumber > 0);
  };

  const onContinue = async (event: any) => {
    event.preventDefault();
    console.log("travelRateValue", travelRateValue);
    const distance = parseFloat(amount);
    const rate = travelRateValue && parseFloat(travelRateValue);
    if (isNaN(distance)) throw Error(`No distance found ${distance}`);

    if (!rate) throw Error(`No rate found ${rate}`);

    calculateMileageRate(distance, distance_unit, rate);
    navigate("/payment/submit/mileage/summary");
  };

  return (
    <CardContainer
      title={t("mileagesCount_HeaderTitle")}
      subtitle={t("mileagesCount_HeaderSubTitle")}
    >
      <form>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel
            data-test-id="amount-label"
            htmlFor="outlined-adornment-amount"
          >
            {t("distance")}
          </InputLabel>

          <OutlinedInput
            data-test-id="amount-input"
            id="outlined-adornment-amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
            inputProps={{
              min: 0,
            }}
            startAdornment={
              <InputAdornment
                data-test-id="amount-start-adornment"
                position="start"
              >
                <AirlinesIcon></AirlinesIcon>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                data-test-id="amount-end-adornment"
                position="end"
              >
                {t(distance_unit)}
              </InputAdornment>
            }
            label={t("distance")}
          />
        </FormControl>

        <LoadingButton
          data-test-id="submit-button"
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          loading={isLoading}
          disabled={isContinueButtonDisabled()}
          onClick={onContinue}
        >
          {t("continue_text")}
        </LoadingButton>
      </form>
    </CardContainer>
  );
}

export default MileageDistancePage;
