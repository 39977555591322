import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
  Box,
  Stack,
  Button,
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { listItemStyle } from "../common/constants";
import PaymentService from "../api/paymentService";
import {
  setLatestPaymentMethod,
  setPaymentMethod,
} from "../redux/slices/paymentMethodsSlice";
import { useNavigate } from "react-router-dom";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { LoadingButton } from "@mui/lab";
import { formatNumber, parsePhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorType, getErrorDetails } from "../common/CustomErrorBoundary";
import { showError } from "../redux/slices/toastSlice";
import StorageManager from "../services/storage";
import {
  removeSpacesAndHyphens,
  stripTextAfterHyphen,
} from "../common/helpers";
import {
  PaymentAccountDetails,
  PaymentType,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { api } from "../api/api";
import { getVirtualCard } from "../api/paymentMethodsService";
import { AxiosError } from "axios";
import { VirtualCardCreateAttributes } from "../api/OpenAPI/api-types";
import { $api } from "../api/OpenAPI/client";

const VccCardReview: React.FC = () => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const data = useSelector(
    (state: RootState) => state.vcc.vccPaymentData
  ) as any;

  const { properties = {}, states } = useSelector((state: RootState) => {
    const paymentMethod = state.paymentMethods.availablePaymentMethods.find(
      (payment) => payment.payment_type === PaymentType.VirtualCard
    );

    const properties = paymentMethod?.properties.find(
      (property) => property.property_name === "address"
    )?.properties;

    const states = properties?.state.options ?? [];

    return { properties, states };
  });

  function getStateName(key: string): any {
    return states.find((state: any) => state.key === key)?.value;
  }

  const getTitleByKey = (key: string): string => {
    const prop = properties[key];
    return prop ? prop.title : key;
  };

  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const { given_name, middle_name, family_name, phone, address, email } = data;

  const { mutate: createCard, isPending: isCreatingCard } = $api.useMutation(
    "post",
    "/payment-methods",
    {
      onError: (error) => {
        console.error("Error creating your card:", error);
      },
      onSuccess: (data) => {
        const paymentMethodId = data.data?.id;
        if (paymentMethodId) handleCardCreation(paymentMethodId);
      },
    }
  );

  const handlePayment = async () => {
    // Processing the zip code
    const processedZip = removeSpacesAndHyphens(
      stripTextAfterHyphen(data.address.zip)
    );

    const cleanedPhone = data.phone.replace(/\s+/g, "");
    const phoneObject = parsePhoneNumber(cleanedPhone);
    const nationalNumber = phoneObject.nationalNumber;
    const countryCallingCode = `+${phoneObject.countryCallingCode}`;

    const createAttributes: VirtualCardCreateAttributes = {
      type: "virtual-card",
      blob: {
        paymentCountryIso: "US",
        givenName: data.given_name,
        middleName: data.middle_name,
        familyName: data.family_name,
        phoneCountryCode: countryCallingCode,
        phone: nationalNumber,
        address: {
          lineOne: address.line_1,
          city: address.city,
          state: address.state,
          zip: processedZip,
        },
        email: data.email.trim(),
      },
    };

    createCard({
      body: {
        data: {
          type: "payment-methods",
          attributes: createAttributes,
        },
      },
    });
  };

  function handleCardCreation(paymentMethodId: string) {
    StorageManager.removeFormData();
    dispatch(setPaymentMethod(paymentMethodId));
    navigate("/new-card-success", { replace: true });
  }

  const addressString = address
    ? Object.entries(address)
        .filter(([key]) => key !== "country_code")
        .map(([key, value]) =>
          key === "state" ? getStateName(value as string) : value
        )
        .join(", ")
    : t("notProvided");

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "white",
        minHeight: "calc(100vh - 56px - 32px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <List sx={{ width: "100%" }}>
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            data-test-id="name-label"
            secondary={t("fullName")}
            primary={`${given_name} ${
              middle_name ? `${middle_name} ` : ""
            }${family_name}`}
          />
        </ListItem>

        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            data-test-id="email-label"
            secondary={t("email")}
            primary={email || t("notProvided")}
          />
        </ListItem>

        <ListItem sx={listItemStyle}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </Grid>
            <Grid item xs>
              <Collapse
                data-test-id="address-label"
                in={true}
                timeout="auto"
                unmountOnExit
              >
                {addressString.split(", ").map((line, index) => (
                  <Box key={index}>
                    <ListItemText
                      data-test-id={"address-" + line}
                      primary={line}
                    />
                  </Box>
                ))}
                <ListItemText secondary={t("billingAddress")} />
              </Collapse>
            </Grid>
          </Grid>
        </ListItem>
        {phone && (
          <ListItem
            sx={{ display: "flex", justifyContent: "space-between", pl: 0 }}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText
              data-test-id="phone-label"
              secondary={t("phone")}
              primary={formatNumber(phone, "US", "INTERNATIONAL")}
            />
          </ListItem>
        )}
      </List>

      <Stack width="100%" spacing={2} justifyContent="center">
        <Button
          fullWidth
          data-test-id="tc-edit-button"
          onClick={() => navigate(-2)}
          size="large"
          variant="text"
          sx={{ color: "gray" }}
          disabled={isCreatingCard}
        >
          <span>{t("vcc_edit")}</span>
        </Button>
        <LoadingButton
          data-test-id="create-button"
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={handlePayment}
          loading={isCreatingCard}
          loadingPosition="start"
          startIcon={<AddCardIcon />}
        >
          <span>{t("vcc_createMyCard")}</span>
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default VccCardReview;
