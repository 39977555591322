import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect } from "react";
import { useAPI } from "../../api/api";
import { getStates } from "../../api/paymentMethodsService";
import { StatesList } from "../../types/paymentTypes";
import { KeyValue } from "../../types/common";

type StateAutocompleteProps = {
  options: KeyValue[];
  label: string;
  selectedStateCode: string;
  handleStateChange: (e: any, value: any) => void;
  disabled: boolean;
};

const StateAutocomplete: React.FC<StateAutocompleteProps> = ({
  options,
  label,
  selectedStateCode,
  handleStateChange,
  disabled,
}) => {
  // const data =
  return (
    <Grid item>
      <Autocomplete
        data-test-id="state"
        id="state-autocomplete"
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.value}
        value={options.find((state) => state.key === selectedStateCode) || null}
        onChange={handleStateChange}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  );
};

export default StateAutocomplete;
