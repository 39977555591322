import moment from "moment";
import { store } from "../redux/store";
import { AvailablePaymentMethod, VehicleQuestion } from "./paymentTypes";

import "moment/dist/locale/bg";
import "moment/dist/locale/de";
import "moment/dist/locale/en-gb";
import "moment/dist/locale/es";
import "moment/dist/locale/fr";
import "moment/dist/locale/pt";
import "moment/dist/locale/vi";
import "moment/dist/locale/zh-cn";
import "moment/dist/locale/it";
import "moment/dist/locale/ja";
import "moment/dist/locale/ko";
import "moment/dist/locale/ar";
import "moment/dist/locale/th";
import "moment/dist/locale/cs";
import "moment/dist/locale/da";
import "moment/dist/locale/et";
import "moment/dist/locale/lt";
import "moment/dist/locale/nl";
import "moment/dist/locale/pl";
import "moment/dist/locale/ru";
import "moment/dist/locale/nn";
import { Visit } from "../api/OpenAPI/api-types";

export interface TransformedVccCardType {
  target_account_id: string;
  target_account_type: string;
  target_account_identifier: string;
  target_account_title: string;
  country_iso: string;
  balance: number;
  currency?: string;
}

// export interface Visit {
//   name: string;
//   window: number;
//   timeframe: number;
//   is_screening: boolean;
//   is_randomisation: boolean;
//   description: string;
//   id: number;
//   is_discontinued: boolean;
//   earliest_date: string;
//   latest_date: string;
//   confirmed_date: string | null;
//   categories: KeyValue[];
// }

export interface KeyValue {
  key: string;
  value: string;
}

export function dateString(visit: Visit): string | null {
  return null;
  // const hideVisitMetaData =
  //   store.getState().auth.profile?.hide_visits_meta_info || visit.attributes.screening;
  // if (hideVisitMetaData) return null;
  // if (visit.attributes.createdAt) {
  //   return formatDateOnly(visit.attributes.createdAt);
  // } else {
  //   const earliest = formatDateOnly(visit.earliest_date);
  //   const latest = formatDateOnly(visit.latest_date);
  //   return `${earliest} - ${latest}`;
  // }
}

export interface Token {
  access_token: string;
  requiresPinChange: boolean;
}

export interface Profile {
  claimant_id: number;
  participant_number: string;
  country: string;
  currency: string;
  is_locked: boolean;
  is_displaced: boolean;
  original_country: string | null;
  name: string | null;
  email: string | null;
  is_email_verified: boolean | null;
  preferred_language: string;
  distance_unit: string;
  available_languages: string[];
  latest_accepted_tc?: Date;
  are_tc_rejected: boolean;
  hide_visits_meta_info: boolean;
  distance_rule_screens: VehicleQuestion[];
  available_claim_types: string[];
}

export interface VisitsResponse {
  visits: Visit[];
}

export function isFutureDate(dateString: string): boolean {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.isAfter();
}

export function formatDateOnly(dateString: string): string {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.format("D MMMM, YYYY");
}

export function formatISODate(dateString: string): string {
  const date = moment(dateString, "YYYY-MM-DDTHH:mm:ss");
  return date.format("D MMMM, YYYY");
}

export const isElectricVehicle = (): boolean => {
  const questions = store.getState().auth.profile?.distance_rule_screens ?? [];
  const electricVehicleQuestion = questions.find(
    (q) => q.property === "is_electric"
  );
  if (
    electricVehicleQuestion &&
    typeof electricVehicleQuestion.selected_option?.value === "boolean"
  ) {
    return electricVehicleQuestion.selected_option.value;
  }
  return false;
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const cleanEmptyProperties = (obj: any, ignoreKey: string = "") => {
  let cleanedObj = Object.entries(obj)
    .filter(([key, value]) => {
      return (
        key !== ignoreKey &&
        value !== null &&
        value !== undefined &&
        value !== ""
      );
    })
    .reduce((acc: any, [key, value]) => ({ ...acc, [key]: value }), {});

  return cleanedObj;
};

export interface LoginData {
  clientId: string;
  yearOfBirth?: number;
  pin: string;
  pin_confirmation?: string;
  isNewUser: boolean;
}

// export const mockTrialVisits: Visit[] = [
//   {
//     id: 1,
//     name: "Initial Screening",
//     window: 3,
//     timeframe: 7,
//     is_screening: true,
//     is_randomisation: false,
//     description: "Initial screening visit to determine eligibility.",
//     is_discontinued: false,
//     earliest_date: "2024-11-01T00:00:00Z",
//     latest_date: "2024-11-03T00:00:00Z",
//     confirmed_date: null,
//     categories: [
//       { key: "BloodTest", value: "Blood Test" },
//       { key: "PhysicalExam", value: "Physical Exam" },
//     ],
//   },
//   {
//     id: 2,
//     name: "Baseline Visit",
//     window: 5,
//     timeframe: 10,
//     is_screening: false,
//     is_randomisation: true,
//     description: "Baseline visit to collect initial study data.",
//     is_discontinued: false,
//     earliest_date: "2024-11-05T00:00:00Z",
//     latest_date: "2024-11-10T00:00:00Z",
//     confirmed_date: "2024-11-06T00:00:00Z",
//     categories: [
//       { key: "Blood Pressure", value: "Blood Pressure" },
//       { key: "ECG", value: "ECG" },
//     ],
//   },
//   {
//     id: 3,
//     name: "Follow-Up Visit",
//     window: 7,
//     timeframe: 30,
//     is_screening: false,
//     is_randomisation: false,
//     description: "Follow-up visit to assess treatment progress.",
//     is_discontinued: false,
//     earliest_date: "2024-12-01T00:00:00Z",
//     latest_date: "2024-12-07T00:00:00Z",
//     confirmed_date: null,
//     categories: [
//       { key: "SymptomCheck", value: "Symptom Check" },
//       { key: "MedicationReview", value: "Medication Review" },
//     ],
//   },
//   {
//     id: 4,
//     name: "Final Visit",
//     window: 5,
//     timeframe: 14,
//     is_screening: false,
//     is_randomisation: false,
//     description: "Final visit to conclude the study.",
//     is_discontinued: false,
//     earliest_date: "2025-01-15T00:00:00Z",
//     latest_date: "2025-01-20T00:00:00Z",
//     confirmed_date: null,
//     categories: [
//       { key: "FinalAssessment", value: "Final Assessment" },
//       { key: "StudyFeedback", value: "Study Feedback" },
//     ],
//   },
// ];

// Mock data for pending stipends
export const mockPendingStipends = [
  {
    id: "1",
    attributes: {
      amount: "200.00",
      dueDate: "2024-12-01T00:00:00Z",
      title: "Study Completion Bonus",
      status: "pending",
    },
  },
  {
    id: "2",
    attributes: {
      amount: "150.00",
      dueDate: "2024-12-15T00:00:00Z",
      title: "Follow-up Visit Stipend",
      status: "pending",
    },
  },
];

export interface ExpenseRowData {
  id: string;
  createdAt: string;
  type: "Mileage" | string;
  amount: string;
  status: "pending" | "approved" | "rejected";
  receipt_url?: string;
}

// Mock data
export const mockExpenses: ExpenseRowData[] = [
  {
    id: "EXP001",
    createdAt: "2024-07-23T14:29:56+00:00",
    type: "Mileage",
    amount: "120.74",
    status: "pending",
  },
  {
    id: "EXP002",
    createdAt: "2024-07-24T10:15:30+00:00",
    type: "Meal",
    amount: "45.50",
    status: "approved",
    receipt_url: "https://example.com/receipt1.jpg",
  },
  {
    id: "EXP003",
    createdAt: "2024-07-25T09:00:00+00:00",
    type: "Hotel",
    amount: "200.00",
    status: "rejected",
    receipt_url: "https://example.com/receipt2.jpg",
  },
];

export function convertDataUrlToBlob(dataUrl: any): Blob {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}
