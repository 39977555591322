import createFetchClient, { Middleware } from "openapi-fetch";
import createClient from "openapi-react-query";
import type { paths } from "./v2"; // generated by openapi-typescript
import StorageManager from "../../services/storage";

const UNPROTECTED_ROUTES: string[] = [
  "/participant/auth/onboard",
  "/participant/auth/login",
];

const myMiddleware: Middleware = {
  async onRequest({ request }) {
    const token = StorageManager.getTokenData()?.access_token;
    const url = new URL(request.url);
    const pathname = url.pathname;

    if (token && !UNPROTECTED_ROUTES.includes(pathname)) {
      request.headers.set("Authorization", `Bearer ${token}`);
    }
    request.headers.set("Content-Type", "application/vnd.api+json");
    request.headers.set("Accept", "application/vnd.api+json");

    // TODO: Add the current language to Accept-Language header

    return request;
  },
};

export const $fetchClient = createFetchClient<paths>({
  baseUrl: import.meta.env.VITE_NMIBLE_SERVER,
});

// register middleware
$fetchClient.use(myMiddleware);

export const $api = createClient($fetchClient);
