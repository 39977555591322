import { DistanceCalculationResult, Rate } from "../api/paymentService";
import { setMileageClaimDistance } from "../redux/slices/claimsSlice";
import { store } from "../redux/store";

export function calculateMileageRate(
  distance: number,
  distance_unit: string,
  ratePerUnit: number
): DistanceCalculationResult {
  // Calculate the total amount based on the distance and rate
  const totalAmount = distance * ratePerUnit;

  // Create a single Rate object
  const rate: Rate = {
    distance,
    rate: ratePerUnit,
    amount: totalAmount,
  };

  const calcResults: DistanceCalculationResult = {
    rates: [rate], // Return a single rate object in an array
    total_distance: distance,
    total_amount: totalAmount,
  };

  // Dispatch the mocked results to the Redux store
  store.dispatch(
    setMileageClaimDistance({
      unit: distance_unit,
      rateCalculationResults: calcResults,
    })
  );

  return calcResults;
}
